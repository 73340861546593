export const trackAddToCart = async (userData) => {
  try {
    const response = await fetch('https://api.theoranjemedia.com/public_html/api/meta/conversion.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        eventType: 'AddToCart',
        userData
      })
    });
    
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    
    const result = await response.json();
    console.log('AddToCart event sent successfully:', result);
    return result;
  } catch (error) {
    console.error('Error sending AddToCart event:', error);
    throw error;
  }
};

export const trackInitiateCheckout = async (userData) => {
  try {
    const response = await fetch('https://api.theoranjemedia.com/public_html/api/meta/conversion.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        eventType: 'InitiateCheckout',
        userData
      })
    });
    
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    
    const result = await response.json();
    console.log('InitiateCheckout event sent successfully:', result);
    return result;
  } catch (error) {
    console.error('Error sending InitiateCheckout event:', error);
    throw error;
  }
};

export const trackPurchase = async (userData) => {
  try {
    const response = await fetch('https://api.theoranjemedia.com/public_html/api/meta/conversion.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        eventType: 'Purchase',
        userData
      })
    });
    
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    
    const result = await response.json();
    console.log('Purchase event sent successfully:', result);
    return result;
  } catch (error) {
    console.error('Error sending Purchase event:', error);
    throw error;
  }
};