import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { CheckCircle } from 'lucide-react';
import { trackPurchase } from './metaUtility';

const Container = styled.div`
  min-height: 100vh;
  background-color: #f9fafb;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`;

const Card = styled.div`
  max-width: 42rem;
  width: 100%;
  background: white;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin: 1rem;
`;

const ContentWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;

const Title = styled.h1`
  font-size: 1.875rem;
  font-weight: bold;
  color: #111827;
`;

const Subtitle = styled.p`
  font-size: 1.25rem;
  color: #4b5563;
`;

const InfoBox = styled.div`
  background-color: #f9fafb;
  padding: 1.5rem;
  border-radius: 0.5rem;
  text-align: left;
`;

const InfoText = styled.p`
  color: #374151;
  margin-bottom: 1rem;
`;

const List = styled.ul`
  list-style-type: disc;
  padding-left: 1.5rem;
  margin-top: 0.5rem;
  color: #4b5563;

  li {
    margin-bottom: 0.5rem;
  }
`;

const SupportText = styled.p`
  font-size: 0.875rem;
  color: #6b7280;
  margin-top: 1.5rem;
`;

const Link = styled.a`
  color: #2563eb;
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
`;

const PaymentSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();
  
  useEffect(() => {
    // Check if the user came from the checkout page and has necessary data
    const referrer = document.referrer;
    const isValidReferrer = referrer.includes('pay.theoranjemedia.com') || referrer.includes('localhost:3000');
    
    if (!isValidReferrer || !location.state?.userData) {
      navigate('/');
      return;
    }
  }, [navigate, location.state]);

  //Track purchase event when component mounts
  
  if (location.state?.userData) {
    trackPurchase(location.state.userData);
  }else{
    window.location.replace("https://pay.theoranjemedia.com");
  }

  const { name, email } = location.state.userData;

  return (
    <Container>
      <Card>
        <ContentWrapper>
          <IconWrapper>
            <CheckCircle size={64} color="#22c55e" />
          </IconWrapper>
          
          <Title>Payment Successful!</Title>
          
          <Subtitle>
            Thank you for your purchase, {name}!
          </Subtitle>

          <InfoBox>
            <InfoText>
              We will send a confirmation email to <strong>{email}</strong> with your course access details.
            </InfoText>
            
            <div>
              <strong>Next Steps:</strong>
              <List>
                <li>Check your email (including spam folder) for course access instructions</li>
              </List>
            </div>
          </InfoBox>

          <SupportText>
            If you don't receive the email within 24 hours, please contact us at{' '}
            <Link href="mailto:admin@theoranjemedia.com">
              admin@theoranjemedia.com
            </Link>
          </SupportText>
        </ContentWrapper>
      </Card>
    </Container>
  );
};

export default PaymentSuccess;