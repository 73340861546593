import { ToastContainer } from 'react-toastify';
import './App.css';
import Checkout from './checkout';
import 'react-toastify/dist/ReactToastify.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PaymentSuccess from './success';
function App() {
  return (
    <div className="App">
      <ToastContainer />
      <Router>
      <Routes>
        <Route path="/" element={<Checkout />} />
        <Route path="/payment-success" element={<PaymentSuccess />} />
      </Routes>
    </Router>
    </div>
  );
}

export default App;
