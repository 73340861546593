import React, { useRef, useState, useCallback, useEffect } from "react";
import { FlutterWaveButton, closePaymentModal } from "flutterwave-react-v3";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import styled from "styled-components";
import axios from "axios";
import Image100 from "./100.webp";
import VideoSrc from "./6.mp4";
import VideoSrc1 from "./3.mp4";
import debounce from "lodash/debounce";
import LoadingSpinner from "./loader";
import { toast } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { PaymentIcon } from 'react-svg-credit-card-payment-icons';
import MetaPixel from "./metapixel";
import { useNavigate } from 'react-router-dom';
import { trackAddToCart, trackInitiateCheckout } from "./metaUtility";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);


const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2rem;
  padding: 5rem;
  background: #f9fafb;
  height: 100vh;
  margin: 0;
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: center;
    height: auto;
    margin: 1rem 0;
    padding: 1rem;
  }
`;

const Content = styled.div`
  flex: 1;
  max-width: 600px;

  img {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 1rem;
  }

  h2 {
    font-size: 1.8rem;
    color: #333333;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1rem;
    color: #555555;
    line-height: 1.5;
  }
`;

const Card = styled.div`
  background: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    ${"" /* width: 90%; */}
  }
`;

const Title = styled.h1`
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 1rem;
  color: #333333;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.8rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const HorizontalLineContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 20px 0;
`;

export const HorizontalLine = styled.div`
  flex-grow: 1;
  height: 1px;
  background-color: #d8d8e5;
  margin: 0 10px;
`;

export const OrText = styled.div`
  color: #8a9297;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
`;

const FloatingButton = styled.button`
  position: fixed;
  bottom: 20px;
  left: 20px;
  padding: 1rem 2rem;
  background-color: #ff6347;
  color: white;
  font-size: 1.2rem;
  border: none;
  border-radius: 50px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  z-index: 1000;

  &:hover {
    background-color: #e5533d;
  }
`;

const PaymentMethodSelector = styled.div`
  margin-bottom: 1rem;
`;

const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
`;

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  
  &:hover {
    background-color: #f5f5f5;
  }
`;

const ChangeMethodButton = styled.button`
  background: none;
  border: none;
  color: #0066cc;
  text-decoration: underline;
  cursor: pointer;
  padding: 0.5rem 0;
  margin-bottom: 1rem;
  
  &:hover {
    color: #004999;
  }
`;

const CardElementContainer = styled.div`
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 4px;
  background: white;
  margin-bottom: 20px;

  &.StripeElement--focus {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
  }

  &.StripeElement--invalid {
    border-color: #dc3545;
  }
`;

const PayButton = styled.button`
  width: 100%;
  padding: 0.8rem;
  background: #5469d4;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.2s ease;
  margin-top: 15px;

  &:hover {
    background: #4254b5;
  }

  &:disabled {
    background: #7887d1;
    cursor: not-allowed;
  }
`;


const CheckoutForm = ({ formData, setIsLoading, sendPaymentUpdate }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!stripe || !elements) {
      return;
    }

    setProcessing(true);
    setError(null);
    setIsLoading(true);

    try {
      await trackInitiateCheckout(formData);
      const { error: submitError } = await elements.submit();
      if (submitError) {
        setError(submitError.message);
        return;
      }

      const { error: stripeError } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/payment-success`,
        },
      });

      if (stripeError) {
        setError(stripeError.message);
        await sendPaymentUpdate(
          { ...formData, processor: "Stripe" },
          "failed"
        );
      }
    } catch (err) {
      setError("An error occurred while processing your payment.");
      console.error("Payment error:", err);
    } finally {
      setProcessing(false);
      setIsLoading(false);
    }
  };

  const paymentElementOptions = {
    layout: "tabs",
    defaultValues: {
      billingDetails: {
        name: formData.name,
        email: formData.email,
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement options={paymentElementOptions} />
      {error && <div style={{ color: 'red', marginBottom: '10px' }}>{error}</div>}
      <PayButton type="submit" disabled={!stripe || processing}>
        {processing ? 'Processing...' : 'Pay $29'}
      </PayButton>
    </form>
  );
};


const usePaymentProcessor = () => {
  const [isLoading, setIsLoading] = useState(false);
  const txRef = useRef(`tx_ref_${new Date().getTime()}`).current;

  const sendPaymentUpdate = async (data, status, processorTxnId = null) => {
    try {
      setIsLoading(true);
      const payload = {
        tx_ref: txRef,
        name: data.name,
        email: data.email,
        course_title: "Parenting in a Digital World: Raising Safe and Smart Kids",
        processor: data.processor,
        payment_status: status,
        ...(processorTxnId && { processor_trnx_id: processorTxnId }),
      };

      await axios.post(
        "https://api.theoranjemedia.com/public_html/api/payment/index.php",
        payload
      );

      if (status === "completed") {
        toast.success("Payment successful! Check your email for course access.");
      } else if (status === "failed") {
        toast.error("Payment failed. Please try again.");
      }
    } catch (error) {
      console.error("Payment update failed:", error);
      toast.error("Error updating payment status");
    } finally {
      setIsLoading(false);
    }
  };

  const initializePayment = async (data, processor) => {
    try {
      const payload = {
        tx_ref: txRef,
        name: data.name,
        email: data.email,
        course_title: "Parenting in a Digital World: Raising Safe and Smart Kids",
        processor,
        payment_status: "pending",
      };

      await axios.post(
        "https://api.theoranjemedia.com/public_html/api/payment/index.php",
        payload
      );
      return true;
    } catch (error) {
      toast.error("Could not initialize payment");
      console.error(error);
      return false;
    }
  };

  return { txRef, isLoading, setIsLoading, initializePayment, sendPaymentUpdate };
};


const validateForm = (data) => {
  const errors = {};
  if (!data.email || !data.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
    errors.email = "Invalid email format";
  }
  if (!data.name || data.name.length < 2) {
    errors.name = "Name too short";
  }
  if (!data.email || data.email.length === 0) {
    errors.email = "Email is required";
  }
  if (!data.name || data.name.length === 0) {
    errors.name = "Name is required";
  }
  return errors;
};

const Checkout = () => {
  const [formData, setFormData] = useState({ name: "", email: "" });
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({ name: false, email: false });
  const [clientSecret, setClientSecret] = useState("");
  const [showPaymentElement, setShowPaymentElement] = useState(false);
  const nameInputRef = useRef(null);
  const { txRef, isLoading, setIsLoading, initializePayment, sendPaymentUpdate } = usePaymentProcessor();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [showPaymentMethods, setShowPaymentMethods] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // First, create the fbq function if it doesn't exist
    window.fbq = window.fbq || function() {
      (window.fbq.q = window.fbq.q || []).push(arguments);
    };
  
    // Load the Facebook Pixel script
    const loadFacebookPixel = () => {
      // Add noscript element
      const noscript = document.createElement('noscript');
      const img = document.createElement('img');
      img.height = 1;
      img.width = 1;
      img.style.display = 'none';
      img.src = `https://www.facebook.com/tr?id=${process.env.REACT_APP_META_PIXEL_ID}&ev=PageView&noscript=1`;
      noscript.appendChild(img);
      document.head.appendChild(noscript);
  
      // Add the main pixel script
      const script = document.createElement('script');
      script.innerHTML = `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
      `;
      document.head.appendChild(script);
  
      // Initialize the pixel
      window.fbq('init', process.env.REACT_APP_META_PIXEL_ID);
      window.fbq('track', 'PageView');
      window.fbq('track', 'Purchase');
    };
  
    loadFacebookPixel();
  
    // Cleanup function
    return () => {
      // Remove the scripts if component unmounts
      const scripts = document.querySelectorAll('script[src*="connect.facebook.net"]');
      scripts.forEach(script => script.remove());
      const noscripts = document.querySelectorAll('noscript');
      noscripts.forEach(noscript => noscript.remove());
    };
  }, []);
  
  // Create a safe tracking function
  const safeTrackEvent = (eventName, data = {}) => {
    if (window.fbq && typeof window.fbq === 'function') {
      try {
        window.fbq('track', eventName, data);
        console.log(`Successfully tracked ${eventName} event`);
      } catch (error) {
        console.error(`Error tracking ${eventName} event:`, error);
      }
    } else {
      console.warn('Facebook Pixel not yet loaded');
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/buy-button.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleStripeSuccess = async () => {
    await sendPaymentUpdate(
      { ...formData, processor: "Stripe" },
      "completed",
      // Note: Stripe transaction ID would ideally come from webhook
      `stripe_${new Date().getTime()}`
    );

    safeTrackEvent('Purchase', {
      value: 29.00,
      currency: 'USD',
      content_name: 'Parenting in a Digital World: Raising Safe and Smart Kids',
      content_type: 'product',
      content_ids: ['PDW001'],
      num_items: 1
    });
    navigate("/payment-success", {
    state: {
      userData: {
        name: formData.name,
        email: formData.email
      }
    }
  });
  };

  const handleStripeFailure = async () => {
    await sendPaymentUpdate(
      { ...formData, processor: "Stripe" },
      "failed"
    );
  };

  const debouncedValidation = useCallback(
    debounce((value, field, currentPayerInfo) => {
      const newErrors = validateForm({ ...currentPayerInfo, [field]: value });
      setErrors((prev) => ({ ...prev, [field]: newErrors[field] }));
    }, 300),
    []
  );

  const handlePaymentMethodChange = async (method) => {
    console.log("User details:", formData);
    setSelectedPaymentMethod(method);
    setShowPaymentMethods(false);
    await initializePayment(formData, method);
  };

  const handleChangePaymentMethod = () => {
    setShowPaymentMethods(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleBlur = (field) => {
    setTouched(prev => ({ ...prev, [field]: true }));
    const validationErrors = validateForm(formData);
    setErrors(validationErrors);
  };

  // Lazy load payment components

  // const handlePaymentSuccess = async (processor, processorTrnxId) => {
  //   try {
  //     await updatePaymentStatus(
  //       { ...payerInfo, processor },
  //       "completed",
  //       processorTrnxId
  //     );
  //     alert(`Payment successful!`);
  //   } catch (error) {
  //     console.error("Success status update failed:", error);
  //   }
  // };

  // const handlePaymentFailure = async (processor, error) => {
  //   try {
  //     await updatePaymentStatus(
  //       { ...payerInfo, processor },
  //       "failed"
  //     );
  //     alert(`Payment failed: ${error.message}`);
  //   } catch (err) {
  //     console.error("Failure status update failed:", err);
  //   }
  // };

  // const generateTxRef = () => `tx_ref_${new Date().getTime()}`;

  // const getRef = () => {
  //   const txRef = generateTxRef();
  //   setCurrentTxRef(txRef);
  // }

  // useEffect(() => {
  //   getRef();
  // },[]);

  // const onInitiate = async (processor) => {

  //   if (!currentTxRef) {
  //     getRef();
  //     console.error("Transaction reference not generated yet.");
  //     onInitiate(processor); // Retry initiation
  //   }

  //   const courseTitle = "Parenting in a Digital World: Raising Safe and Smart Kids"; // Hardcoded course title

  //   const transaction = {
  //     name: payerInfo.name,
  //     email: payerInfo.email,
  //     course_title: courseTitle,
  //     tx_ref: currentTxRef,
  //     processor: processor,
  //     payment_status: "pending",
  //   };

  //   try {
  //     await axios.post("https://api.oranjemedia.com/api/initiate-payment", transaction);
  //     console.log("Transaction initiated successfully");
  //   } catch (error) {
  //     console.error("Error initiating transaction:", error);
  //   }
  // };

  // const onBackendUpdate = async (data, txRef, processor, paymentStatus, processorTrnxId) => {
  //   const courseTitle = "Parenting in a Digital World: Raising Safe and Smart Kids"; // Hardcoded course title
  //   const transaction = {
  //     name: data.name,
  //     email: data.email,
  //     course_title: courseTitle,
  //     tx_ref: txRef,
  //     processor: processor,
  //     payment_status: paymentStatus,
  //     ...(processorTrnxId && { processor_trnx_id: processorTrnxId }), // Include only if provided
  //   };
  //   try {
  //     await axios.post("https://api.oranjemedia.com/api/payment", transaction);
  //     console.log("Backend updated successfully");
  //   } catch (error) {
  //     console.error("Error updating backend:", error);
  //   }
  // };

  // const onPaymentSuccess = async (paymentMethod, processorTrnxId) => {
  //   const txRef = currentTxRef || generateTxRef(); // Get the stored tx_ref
  //   alert(`${paymentMethod} payment successful!`);
  //   await onBackendUpdate(payerInfo, txRef, paymentMethod, "completed", processorTrnxId);
  // };

  // const onPaymentFailure = async (error, paymentMethod) => {
  //   const txRef = currentTxRef || generateTxRef(); // Get the stored tx_ref
  //   alert(`${paymentMethod} payment failed: ${error.message || error}`);
  //   await onBackendUpdate(payerInfo, txRef, paymentMethod, "failed");
  // };

  // const handleFlutterwavePayment = async () => {
  //   const processor = "Flutterwave";
  //   onInitiate(processor);
  //   try { // Get the tx_ref dynamically
  //      // Store the tx_ref in state
  //     flutterwaveConfig.tx_ref = currentTxRef; // Ensure tx_ref is passed to Flutterwave
  //     closePaymentModal(); // Ensure any old modal is closed before re-initiation
  //   } catch (error) {
  //     console.error("Error initiating Flutterwave payment:", error);
  //   }
  // };

  // const renderPaymentMethodSelector = () => (
  //   <PaymentMethodSelector>
  //     {showPaymentMethods ? (
  //       <RadioGroup>
  //         <RadioLabel>
  //           <input
  //             type="radio"
  //             name="paymentMethod"
  //             value="PayPal"
  //             checked={selectedPaymentMethod === "PayPal"}
  //             onChange={() => handlePaymentMethodChange("PayPal")}
  //           />
  //           Pay with PayPal
  //         </RadioLabel>
  //         <RadioLabel>
  //           <input
  //             type="radio"
  //             name="paymentMethod"
  //             value="Flutterwave"
  //             checked={selectedPaymentMethod === "Flutterwave"}
  //             onChange={() => handlePaymentMethodChange("Flutterwave")}
  //           />
  //           Pay with Flutterwave
  //         </RadioLabel>
  //       </RadioGroup>
  //     ) : (
  //       <ChangeMethodButton onClick={handleChangePaymentMethod}>
  //         Change payment method
  //       </ChangeMethodButton>
  //     )}
  //   </PaymentMethodSelector>
  // );

  const renderPaymentMethodSelector = () => (
    <PaymentMethodSelector>
      {showPaymentMethods ? (
        <RadioGroup>
          {/* <RadioLabel>
            <input
              type="radio"
              name="paymentMethod"
              value="PayPal"
              checked={selectedPaymentMethod === "PayPal"}
              onChange={() => handlePaymentMethodChange("PayPal")}
            />
            Pay with PayPal
          </RadioLabel>
          <RadioLabel>
            <input
              type="radio"
              name="paymentMethod"
              value="Flutterwave"
              checked={selectedPaymentMethod === "Flutterwave"}
              onChange={() => handlePaymentMethodChange("Flutterwave")}
            />
            Pay with Flutterwave
          </RadioLabel> */}
          <RadioLabel>
            <input
              type="radio"
              name="paymentMethod"
              value="Stripe"
              checked={selectedPaymentMethod === "Stripe"}
              onChange={() => handlePaymentMethodChange("Stripe")}
            />
            Pay with Stripe
          </RadioLabel>
        </RadioGroup>
      ) : (
        <ChangeMethodButton onClick={handleChangePaymentMethod}>
          Change payment method
        </ChangeMethodButton>
      )}
    </PaymentMethodSelector>
  );

  // Payment processor specific configurations
  const renderPaymentButton = () => {
    if (!selectedPaymentMethod || showPaymentMethods) return null;

    if (selectedPaymentMethod === "PayPal") {
      return (
        <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID, intent: "venmo, apple pay" }}>
          <PayPalButtons
            style={{ layout: "vertical" }}
            createOrder={async (_, actions) => {
              //await initializePayment(formData, "PayPal");
              return actions.order.create({
                purchase_units: [{ amount: { value: "29" } }],
              });
            }}
            onApprove={async (_, actions) => {
              const order = await actions.order.capture();
              await sendPaymentUpdate(
                { ...formData, processor: "PayPal" },
                "completed",
                order.id
              );
            }}
            onError={async () => {
              await sendPaymentUpdate(
                { ...formData, processor: "PayPal" },
                "failed"
              );
            }}
            onCancel={async () => {
              await sendPaymentUpdate(
                { ...formData, processor: "PayPal" },
                "failed"
              );
            }}
          />
        </PayPalScriptProvider>
      );
    }

    if (selectedPaymentMethod === "Flutterwave") {
      const flutterwaveConfig = {
        public_key: process.env.REACT_APP_FLUTTERWAVE_KEY,
        tx_ref: txRef,
        amount: 29.9,
        currency: "USD",
        country: "",
        payment_options: "card,banktransfer,ussd,account,applepay,googlepay,paypal",
        customer: {
          email: formData.email,
          name: formData.name,
        },
        customizations: {
          title: "Parenting in a Digital World",
          description: "Raising Safe and Smart Kids",
          logo: "https://academy.theoranjemedia.com/logo.jpeg",
        },
        callback: async (response) => {
          if (response.status === "successful") {
            await sendPaymentUpdate(
              { ...formData, processor: "Flutterwave" },
              "completed",
              response.transaction_id
            );
          } else {
            await sendPaymentUpdate(
              { ...formData, processor: "Flutterwave" },
              "failed"
            );
          }
          closePaymentModal();
        },
        onClose: async () => {
          await sendPaymentUpdate(
            { ...formData, processor: "Flutterwave" },
            "failed"
          );
          closePaymentModal();
        },
      };

      return (
        <FlutterWaveButton
          {...flutterwaveConfig}
          text="Pay with Flutterwave"
          className="flw"
          //onClick={() => initializePayment(formData, "Flutterwave")}
        />
      );
    }
    if (selectedPaymentMethod === "Stripe") {
      return (
        <div id="stripe-button">
          <stripe-buy-button
            buy-button-id="buy_btn_1QfR0tLvfz0U1kkwCWAGS3hN"
            publishable-key="pk_live_51Qcwx0Lvfz0U1kkw4bS9pu8JHL2Q4Bll4Ta3b1nIt9doYgzgl01XPg6wFbVyNICpvLdYFXum4ihxm4hbGS9d6w89007XniCtX4"
            onBeforeLoad={() => {
              // You might want to show a loading state here
            }}
            onLoad={() => {
              // Payment initialized
            }}
            onSuccess={handleStripeSuccess}
            onError={handleStripeFailure}
          ></stripe-buy-button>
        </div>
      );
    }
  };

  const handleBuyNowClick = () => {
    nameInputRef.current?.focus();
  };

  const createPaymentIntent = async (formData) => {
    if (!formData.name || !formData.email) {
      return;
    }

    try {
      setIsLoading(true);
      const formDataObj = new FormData();
      formDataObj.append('email', formData.email);
      formDataObj.append('name', formData.name);
      
      const response = await axios.post(
        "https://api.theoranjemedia.com/public_html/api/payment/create-payment-intent.php",
        formDataObj,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        }
      );
      
      setClientSecret(response.data.clientSecret);
      setShowPaymentElement(true);
    } catch (err) {
      console.error("Error creating payment intent:", err);
      toast.error("Could not initialize payment system");
    } finally {
      setIsLoading(false);
    }
  };

  const handleContinue = async (e) => {
    e.preventDefault();
    if (!formData.name || !formData.email) {
      alert("Please fill in your name and email to continue");
      setErrors({ name: "Name is required", email: "Email is required" });
    }
    const validationErrors = validateForm(formData);
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      await trackAddToCart(formData); // Add this line
      await initializePayment(formData, "Stripe");
      await createPaymentIntent(formData);
    }
  };

  const appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#5469d4',
    },
  };

  const options = {
    clientSecret,
    appearance,
    // amount: 2900,
    // currency: 'usd',
    // mode: 'payment',
  };


  return (
    <>
    {/* <MetaPixel /> */}
      <Container>
      {isLoading && <LoadingSpinner />}
      <Content>
        <video
          src={VideoSrc} // If imported, or use '/videos/6.mp4' if in public folder
          autoPlay
          loop
          muted
          playsInline
          style={{ width: "100%", borderRadius: "8px", marginBottom: "1rem" }}
        />
        <h2>
          {" "}
          Parenting in a Digital World: Raising Safe and Smart Kids SALE!
        </h2>
        <p style={{ fontStyle: "italic" }}>
          LIFETIME ACCESS <del style={{ color: "red" }}>$150</del>{" "}
          <strong style={{ color: "green" }}>$29</strong>
        </p>
        <p>
          {" "}
          <p>
            Parenting In The Digital World Has Never Gotten So Easy - One Course
            That Reveals Everything You Need To Know About Parenting In Today's
            Digital World! Parenting in this digital age can feel overwhelming.{" "}
            <br></br> <br />
            With social media, online games, and countless apps shaping your
            child's world, how do you ensure they use technology safely and
            responsibly?<br></br> <br />
            <i>
              "Navigating the Digital World: A Parent's Guide to Technology,
              Social Media, and Keeping Your Child Safe and Responsible Online"
            </i>{" "}
            <br></br> <br />
            is your comprehensive guide to parenting in today's fast-paced
            digital environment.
          </p>
          <h3>This course will equip you with the knowledge to</h3>
          <ul style={{ textAlign: "left", listStyle: "none" }}>
            <li>✅ Understand the platforms your kids love.</li>
            <li>✅ Spot potential online risks.</li>
            <li>✅ Learn social media slang kids use.</li>
            <li>✅ Understand healthy online habits for your child.</li>
            <li>✅ Learn practical ways for setting online boundaries. </li>
            <li>✅ Monitor your child's online activity </li>
            <li>✅ Manage screen time </li>
            <li>
              ✅ Create an open, judgment-free space for conversations about you
              technology and social media.
            </li>
          </ul>
          <img
            src="https://academy.theoranjemedia.com/parentingreview.png"
            alt="reviews"
            style={{ width: "500px", maxWidth: "100%" }}
          />
          <h1>
            Secure your kids online presence in this digital age. -{" "}
            <b style={{ color: "red" }}>ONLY $29</b>
          </h1>
          <img src={Image100} alt="100% Satisfaction" />
        </p>
      </Content>
      <div>
        <Card>
          <Title>
            <del>$150</del> <strong>$29</strong> - Parenting in a Digital
            World:{" "}
            <p style={{ fontWeight: "normal" }}>Raising Safe and Smart Kids</p>
          </Title>
          <div style={{ textAlign: "left" }}>
            {!showPaymentElement ? (
              <form onSubmit={handleContinue}>
                <Input
                  type="text"
                  name="name"
                  placeholder="Your Name"
                  value={formData.name}
                  onBlur={() => handleBlur("name")}
                  onChange={handleInputChange}
                  ref={nameInputRef}
                  required
                />
                {errors.name && touched.name && (
                  <p style={{ color: "red", fontSize: "0.8rem", marginTop: "0.02rem" }}>
                    {errors.name}
                  </p>
                )}
                
                <Input
                  type="email"
                  name="email"
                  placeholder="Your Email"
                  value={formData.email}
                  onBlur={() => handleBlur("email")}
                  onChange={handleInputChange}
                  required
                />
                {errors.email && touched.email && (
                  <p style={{ color: "red", fontSize: "0.8rem", marginTop: "0.02rem" }}>
                    {errors.email}
                  </p>
                )}
                <PayButton type="submit">
                  Continue to Payment
                </PayButton>
                <div style={{justifyContent: 'center', alignContent: 'center', display: 'grid', marginTop: '10px'}}>
                  <span style={{fontSize:'12px',justifyContent: "center",display: 'flex', margin: '3px'}}>we accept</span>
                  <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '20px', gap: '10px'}}>
                    {/* <RiVisaLine size={35} />
                    <FaCcMastercard size={35}/>
                    <FaCcAmex size={35}/>
                    <SiDiscover size={35} />
                    <SiCashapp size={35} /> */}
                    <PaymentIcon type="visa" format="flatRounded" width={30} />
                    <PaymentIcon type="mastercard" format="flatRounded" width={30} />
                    <PaymentIcon type="amex" format="flatRounded" width={30} />
                    <PaymentIcon type="discover" format="flatRounded" width={30} />
                    <img src="https://www.logo.wine/a/logo/Cash_App/Cash_App-Logo.wine.svg" alt="cashapp" style={{width: "60px", marginLeft:'-20px'}}/>
                    <img src="https://www.logo.wine/a/logo/Google_Pay/Google_Pay-Logo.wine.svg" alt="gpay" style={{width: "60px", marginLeft:'-25px'}}/>
                    <img src="https://www.logo.wine/a/logo/Apple_Pay/Apple_Pay-Logo.wine.svg" alt="applepay" style={{width: "60px", marginLeft:'-15px'}}/>
                    <img src="https://www.logo.wine/a/logo/Amazon_Pay/Amazon_Pay-Logo.wine.svg" alt="amazonpay" style={{width: "60px", marginLeft:'-15px'}}/>
                    {/* <PaymentIcon type="cashapp" format="flatRounded" width={30} />
                    <PaymentIcon type="amazonpay" format="flatRounded" width={30} />
                    <PaymentIcon type="applepay" format="flatRounded" width={30} />
                    <PaymentIcon type="gpay" format="flatRounded" width={30} /> */}
                  </div>
                </div>
              </form>
            ) : (
              clientSecret && (
                <Elements stripe={stripePromise} options={options}>
                  <CheckoutForm 
                    formData={formData}
                    setIsLoading={setIsLoading}
                    sendPaymentUpdate={sendPaymentUpdate}
                  />
                </Elements>
              )
            )}
          </div>
          <h3 style={{ color: "red", marginTop: "35px" }}>What You'll Learn</h3>
          <div style={{ margin: "5px", textAlign: "left" }}>
            <p>✔ Guide your kids through their online adventures confidently</p>
            <p>✔ Teach your kids how to manage screen time efficiently</p>
            <p>
              ✔ Know how to navigate social media apps and keep your kids safe
              in them
            </p>
            <p>✔ Teach your kids how to use social media responsibly</p>
            <p>✔ Teach your kids how to handle cyberbully and peer pressure</p>
            <p>
              ✔ Teach your kids how to avoid and handle inappropriate content
            </p>
            <p>✔ Encourage your kids to use technology in a productive way</p>
          </div>
          {/* <ButtonWrapper>
            {renderPaymentButton()}
          </ButtonWrapper> */}
          <h2>
            <b style={{ color: "green" }}>100% Satisfaction Guaranteed </b>
          </h2>
          <small>
            <i>
              If you are not completely SATISFIED and ENLIGHTENED at the end of
              this course, there is 30 day money-back guarantee, you can contact
              us within 30 days for a full refund.{" "}
              <a
                href="mailto:admin@theoranjemedia.com"
                style={{ color: "blue", textDecoration: "underline" }}
              >
                admin@theoranjemedia.com
              </a>
            </i>
          </small>
        </Card>
        <video
          src={VideoSrc1} // If imported, or use '/videos/6.mp4' if in public folder
          autoPlay
          loop
          muted
          playsInline
          style={{ width: "350px", borderRadius: "8px", marginTop: "1rem" }}
        />
      </div>
      <FloatingButton onClick={handleBuyNowClick}>Buy Now</FloatingButton>
    </Container>
    </>
  );
};

export default Checkout;
